<template>
  <div class="box">
    <van-nav-bar
      :left-text="$t('toback')"
      class="select-symbol"
      left-arrow
      @click-left="back"
    />
    <van-radio-group v-model="radio">
      <form action="/">
        <van-search
          v-model="searchValue"
          show-action
          input-align="center"
          :placeholder="$t('Mt4ApiTrade.searchSymbol')"
          @search="onSearch"
          @cancel="onCancel"
        />
      </form>

      <van-cell-group v-for="item in searchList" :key="item.symbol">
        <van-cell :title="item.symbol" clickable @click="changeSymbol(item)">
          <template #right-icon>
            <van-radio :name="item.symbol" checked-color="#13b5b1" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
import { GetLastPrice } from "@/api/mt4Api";
export default {
  name: "Symbols",
  data() {
    return {
      radio: "XAUUSD",
      list: [],
      searchList: [],
      path: "",
      searchValue: "",
    };
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", false);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
    this.radio = this.$route.query.symbol;
    this.path = this.$route.query.path;
    this.loadSymbols();
  },
  created() {
    this.radio = this.symbol;
  },
  methods: {
    back() {
      this.$router.back();
    },
    changeSymbol(item) {
      this.radio = item.symbol;
      if (this.path == "NewOrder") {
        this.$router.push({
          path: "/neworder",
          query: {
            symbol: item.symbol,
            orderType: 0,
            digits: item.digits,
            cmd: 1,
          },
        });
      } else if (this.path == "Chart") {
        this.$router.push({
          path: "/chart",
          query: {
            symbol: item.symbol,
            digits: item.digits,
          },
        });
      } else {
      }
    },
    loadSymbols() {
      var locals = localStorage.getItem("getSymbols");
      if (locals == null) {
        GetLastPrice().then((res) => {
          this.list.push(...res.msg.AllSymbol);
          this.searchList.push(...res.msg.AllSymbol);
          localStorage.setItem("getSymbols", JSON.stringify(res.msg.AllSymbol));
        });
      } else {
        var sym = JSON.parse(locals);
        this.list.push(...sym);
        this.searchList.push(...sym);
      }
    },
    onSearch(val) {
      this.searchList = [];
      this.list.forEach((x) => {
        if (x.symbol.indexOf(val.toUpperCase()) != -1) {
          this.searchList.push(x);
        }
      });
    },
    onCancel() {
      this.searchList = this.list;
    },
  },
};
</script>
<style>
.select-symbol .van-nav-bar__content {
  background-color: #13b5b1;
}

.select-symbol .van-nav-bar,
.select-symbol .van-icon,
.select-symbol .van-nav-bar__text {
  color: #fff;
}
</style>
<style scoped>
div[aria-checked="false"].van-radio {
  display: none;
}
</style>
